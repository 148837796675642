<template>
  <page-view :title="title" left-arrow white-bg>
    <div class="container mt-10 mb-10">
      <card class="yh-jbxx">
        <template #header>
        <van-cell :title="yhJbxx.yhmc + ' | ' +yhJbxx.yhbh">
          <template #extra>
            <yhbh-select v-model="yhbh" @confirm="yhbhChangeHandle">
              <template #trigger>
                <van-button type="primary" icon="exchange" class="van-button--text-primary">切换户号</van-button>
              </template>
            </yhbh-select>
          </template>
        </van-cell>
        </template>
       
        <van-cell-group>
          <van-cell title="当前欠费：" :value="yhJbxx.dqqf | currency"/>
          <van-cell title="预存余额：" :value="yhJbxx.dqjy | currency"/>
        </van-cell-group>
      </card>
    </div>
    <div class="container">
      <h2 class="inline-title">选择预存金额</h2>
      <van-row :gutter="10" type="flex" justify="space-between" class="mb-10">
        <van-col span="8"><van-button plain block :type="yfkActiveIndex == 0 ? 'primary' : 'default'" @click="onYfkSelect(10,0)">10元</van-button></van-col>
        <van-col span="8"><van-button plain block :type="yfkActiveIndex == 1 ? 'primary' : 'default'" @click="onYfkSelect(20,1)">20元</van-button></van-col>
        <van-col span="8"><van-button plain block :type="yfkActiveIndex == 2 ? 'primary' : 'default'" @click="onYfkSelect(50,2)">50元</van-button></van-col>
      </van-row>
      <van-row :gutter="10" type="flex" justify="space-between" class="mb-10">
        <van-col span="8"><van-button plain block :type="yfkActiveIndex == 3 ? 'primary' : 'default'" @click="onYfkSelect(100,3)">100元</van-button></van-col>
        <van-col span="8"><van-button plain block :type="yfkActiveIndex == 4 ? 'primary' : 'default'" @click="onYfkSelect(200,4)">200元</van-button></van-col>
        <van-col span="8">
          <!-- <input placeholder="自定义金额" type="number"/> -->
          <van-field v-model="customInput" type="text" placeholder="自定义金额" class="custom-input-field" @input="onCustomInput"/>
        </van-col>
      </van-row>

      <div class="text-right" style="color: #909090; font-size: 14px;">
        预存金额： {{cryfk | currency}}
      </div>
      <van-divider />
    </div>

    <div style="margin: 16px;">
      <van-button block type="primary" @click="onSubmit">{{cryfk | currency}} 存入预付款</van-button>
    </div>
  </page-view>
</template>

<script>
import { getOpenId, setOpenId } from '@/utils/auth';
import wxPay from '@/utils/wx-pay';
import YhbhSelect from '@/components/YhbhSelect'
import { Divider } from 'vant'
import { yhJbxx_fetchOneYhJbxxByYhbh } from '@/api/zhcx';
import { ydzfService_wxzf } from '@/api/dfzw';
export default {
  name: 'Yfkcr',
  components: {
    [Divider.name]: Divider,
    YhbhSelect
  },
  data() {
    return {
      title: '存入预付款',
      yhbh: '',
      yhJbxx: {
        yhmc: '',
        yhbh: '',
        jgbm: ''
      },
      cryfk: 10,
      yfkActiveIndex: 0,
      customInput: ''
    }
  },
  methods: {
    getYhJbxx() {
      yhJbxx_fetchOneYhJbxxByYhbh(this.yhbh).then(res => {
        if (res.content) {
          this.yhJbxx = res.content
        }
      })
    },
    yhbhChangeHandle(yhxx) {
      this.yhbh = yhxx.yhbh;
      this.getYhJbxx();
    },
    onYfkSelect(value, index) {
      this.cryfk = value;
      this.yfkActiveIndex = index;
    },
    onCustomInput(value) {
      this.cryfk = parseFloat(value);
      this.yfkActiveIndex = null;
    },
    onSubmit() {
      console.log(this.$conf);
      let params = {
        yhbh: this.yhbh,
        jgbm: this.yhJbxx.jgbm,
        cryfk: this.cryfk,
        xzbz: '0', // 现转标志： 0-现金, 1-转账
        zflx: '1', // 支付类型： 1-预付款存入,  2-缴费
        czyh: this.$conf.DEFAULT_PAY_CZYH, // 操作员号(支付业务操作员)
        openid: this.openId
      }
      this.$toast.loading('处理中...')
      // 生成预付订单
      ydzfService_wxzf(params).then(res => {
        if (res.status == 200) {
          // let payInfo = {
          //   "appId": "wx2421b1c4370ecxxx", //公众号ID，由商户传入    
          //   "timeStamp": "1395712654", //时间戳，自1970年以来的秒数    
          //   "nonceStr": "e61463f8efa94090b1f366cccfbbb444", //随机串    
          //   "package": "prepay_id=up_wx21201855730335ac86f8c43d1889123400",
          //   "signType": "RSA", //微信签名方式：    
          //   "paySign": "oR9d8PuhnIc+YZ8cBHFCwfgpaK9gd7vaRvkYD7rthRAZ\/X+QBhcCYL21N7cHCTUxbQ+EAt6Uy+lwSN22f5YZvI45MLko8Pfso0jm46v5hqcVwrk6uddkGuT+Cdvu4WBqDzaDjnNa5UK3GfE1Wfl2gHxIIY5lLdUgWFts17D4WuolLLkiFZV+JSHMvH7eaLdT9N5GBovBwu5yYKUR7skR8Fu+LozcSqQixnlEZUfyE55feLOQTUYzLmR9pNtPbPsu6WVhbNHMS3Ss2+AehHvz+n64GDmXxbX++IOBvm2olHu3PsOUGRwhudhVf7UcGcunXt8cqNjKNqZLhLw4jq\/xDg==" //微信签名
          // };
          let payInfo = res.data;
          wxPay(payInfo, function(res) {
            this.$toast.success('预付款存入成功');
            this.$router.push({
              path: `/yfkcr/${this.yhbh}/result`, 
              query: params
            });
          },function (err) {
            this.$toast.fail(err.message);
          },this);
        } else {
          this.$toast(res.message);
        }
      }).catch(err => {
        this.$toast.fail('存入预付款失败')
      });
    }
  },
  mounted() {
    this.yhbh = this.$route.params.yhbh;
    this.openId = getOpenId();
    this.getYhJbxx();
  },
}
</script>

<style lang="scss" scoped>
.container {
  margin: 0 16px;
}
.van-col--8 {
  flex: 1;
}

.custom-input-field /deep/{
  font-size: 14px;
  width: 100%;
  border: 1px solid #ebedf0;
  // border: 1px solid #8cc4fc;
  border-radius: 4px;
  padding: 0 15px;
  box-sizing: border-box;
  .van-field__control {
    height: 44px;
  }
}
</style>